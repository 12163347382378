.image-container {
  width: 100%;        
  overflow: hidden;    
  padding: 0;          
  margin: 0;         
}

.image-container img {
  width: 100%;  
  height: auto;        
  object-fit: cover;    
  transition: transform 0.4s ease-in-out;    
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  cursor: zoom-in;
  transform: scale(1); 

}

.zoom-on-hover:hover {
  transform: scale(1.2); 
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 0;
}

.popup-content {
  position: relative;
  background-color: white;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; 
}

.popup-slider {
  width: 80%;
  height: 80vh; 
}

.popup-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the slide fills the container */
}

.popup-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 10000;
}

.opacity-1{
  opacity: 1;
}

.w-image{
  width: 100% !important;
  height: 270px !important;
  object-fit: contain !important;
  object-position: top !important;
}

.cursor-zoom-in{
  cursor: zoom-in; 
}